<template>
    <div>
        <p v-if="hintText">{{ hintText }}</p>
        <v-checkbox :rules="isRequired ? rules : []"
                    :label="getLocalizedValue(option.option)"
                    v-model="selectedValues"
                    v-for="(option, index) in options"
                    :key="index"
                    :value="option.id">
        </v-checkbox>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'Checkbox',
    mixins: [
        GetLocalizedValue
    ],
    props: {
        options: {
            type: Array,
            default: () => {
                return [];
            }
        },
        hintText: {
            default: ''
        },
        isRequired: {
            default: true
        },
        questionId: {}
    },
    data() {
        return {
            selectedValues: [],
            rules: [
                v => v.length > 0 || this.$t('fields.checkbox.min_one')
            ]
        };
    },
    computed: {
        ...mapState('surveys', ['currentSurveyAnswers'])
    },
    watch: {
        selectedValues: function() {
            this.updateAnswer();
        }
    },
    methods: {
        updateAnswer() { // id, index
            /* if (!this.selectedValues.includes(id)) {
                this.selectedValues.push(id);
            } else {
                this.selectedValues.splice(index, 1);
            } */
            const values = this.selectedValues.join(',');
            this.$emit('updateAnswer', values);
        },
        checkForAnswer() {
            if (this.currentSurveyAnswers) {
                this.currentSurveyAnswers.forEach((answer) => {
                    if (answer[this.questionId]) {
                        const checkboxValues = answer[this.questionId].split(',');
                        checkboxValues.forEach((checkbox) => {
                            this.selectedValues.push(checkbox);
                        });
                    }
                });
            }
        }
    },
    created() {
        this.checkForAnswer();
    }
};
</script>
